<template>
  <div>
    <Header />
    <div class="md:h-480 h-96 w-full bg-black bg-opacity-30 relative">
      <img
        src="../assets/hero-about.png"
        alt="Hero About"
        class="h-full w-full absolute object-cover mix-blend-overlay -z-10"
      />
      <div
        class="h-full w-full flex justify-center items-center text-center text-white font-bold"
      >
        <h1 class="md:text-7xl text-4xl">Our Partner</h1>
      </div>
    </div>
    <div class="my-12 md:mx-32 mx-6 grid grid-cols-3 items-center gap-12">
      <div class="flex justify-center items-center">
        <img
          src="../assets/partner-ruangoutdoor.png"
          alt=""
          title="Ruang Outdoor"
        />
      </div>
      <div class="flex justify-center items-center">
        <img src="../assets/partner-urbanair.png" alt="" title="Urban Air" />
      </div>
      <div class="flex justify-center items-center">
        <img src="../assets/partner-yukmanjat.png" alt="" title="Yuk Manjat" />
      </div>
      <div class="flex justify-center items-center">
        <img
          src="../assets/partner-ujungkulon.png"
          alt=""
          title="Ujungkulon Adventure"
        />
      </div>
      <div class="flex justify-center items-center">
        <img
          src="../assets/partner-goatv.png"
          alt=""
          title="Go ATV Adventure Bali"
        />
      </div>
      <div class="flex justify-center items-center">
        <img
          src="../assets/partner-mazudivers.png"
          alt=""
          title="Mazu Divers"
        />
      </div>
      <div class="flex justify-center items-center">
        <img
          src="../assets/partner-themangrove.png"
          alt=""
          title="The Mangrove Paddling Center"
        />
      </div>
      <div class="flex justify-center items-center">
        <img
          src="../assets/partner-triptangkahan.png"
          alt=""
          title="Trip Tangkahan"
        />
      </div>
      <div class="flex justify-center items-center">
        <img src="../assets/partner-supyoga.jpg" alt="" title="SUP Yoga" />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: { Header, Footer },
  data: () => ({
    heroStyle: {
      backgroundImage: "url('./src/assets/hero-about.png')",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
  }),
};
</script>
